<template>
  <b-card title="Add Customer">
    <div
      v-if="loading"
      class="row mt-4">
      <div class="col-12 d-flex justify-content-center">
        <div
          class="spinner spinner-border"
          role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>

    <template v-else>
      <b-row>
        <b-col cols="3">
          <b-form-group
            :state="!$v.onboardingClientSlug.$invalid"
            label="Onboarding Client URL">
            <b-form-select
              v-model="onboardingClientSlug"
              :options="onboardingClientSlugs"
              :state="!$v.onboardingClientSlug.$invalid"
              text-field="slug"
              value-field="slug">
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled>
                  Select URL
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>

          <b-form-group
            :state="customerKeyValidationState"
            description="Unique customer key"
            invalid-feedback="Invalid customer key"
            label="Customer Key"
            label-for="customer-key">
            <b-form-input
              id="customer-key"
              v-model="customer_key"
              :state="customerKeyValidationState"
              placeholder="Enter Customer Key"
              type="text" />
          </b-form-group>

          <b-form-group
            description="URL to return to partner on onboarding summary page"
            label="Return to partner URL"
            label-for="return-partner-url">
            <b-form-input
              id="return-partner-url"
              v-model="returnPartnerUrl"
              placeholder="Return partner URL"
              type="text" />
          </b-form-group>

          <b-button-group>
            <b-button
              :disabled="creating || $v.$invalid"
              variant="success"
              @click="save">
              Save
            </b-button>
            <b-button
              variant="danger"
              @click="$emit('close')">
              Cancel
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
    </template>
  </b-card>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { createCustomer } from '@/api/saml/client/integrations';

export default {
  name: 'ClientAddIntegration',
  props: {
    uuid: {
      type: String,
      required: true,
    },
    onboardingClientSlugs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      creating: false,
      customer_key: null,
      onboardingClientSlug: null,
      returnPartnerUrl: null,
    };
  },
  validations() {
    return {
      customer_key: {
        required,
      },
      onboardingClientSlug: {
        required,
      },
    };
  },
  computed: {
    customerKeyValidationState() {
      return !this.$v.customer_key.$invalid;
    },
  },
  beforeMount() {
    if (this.onboardingClientSlugs.length === 1) {
      this.onboardingClientSlug = this.onboardingClientSlugs[0].slug;
    }
  },
  methods: {
    save() {
      this.creating = true;
      const data = {
        customer_key: this.customer_key,
        onboarding_client_slug: this.onboardingClientSlug,
      };
      if (this.returnPartnerUrl !== null && this.returnPartnerUrl.trim().length > 0) {
        data.return_partner_url = this.returnPartnerUrl;
      }
      createCustomer(this.uuid, data)
        .then(() => {
          this.$noty.success('Integration created successfully');
          this.$emit('close', true);
        })
        .catch(err => {
          if (err.response?.status === 409) this.$noty.error('Customer Key is already in use');
          else this.$noty.error(`Failed to create integration: ${err}`);
        })
        .finally(() => {
          this.creating = false;
        });
    },
  },
};
</script>
