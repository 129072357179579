<template>
  <b-container
    id="sso-integration-edit"
    class="mt-4"
    fluid>
    <b-row>
      <b-col cols="6">
        <h3>Integration - Customers</h3>
        <router-link :to="`/onboarding/clients/${$route.params.uuid}/sso/integrations`"
          class="d-flex justify-content-left align-items-center">
          <b-icon aria-label="Back to integration" icon="arrow-left" />
          Back to Integration
        </router-link>
      </b-col>
      <b-col cols="6">
        <b-button
          :disabled="addingCustomer"
          class="float-right"
          variant="primary"
          @click="openAddCustomer">
          <feather type="plus" />
          Add Customer
        </b-button>
      </b-col>
    </b-row>
    <b-row
      v-if="addingCustomer"
      class="mt-3">
      <b-col>
        <add-customer
          :uuid="$route.params.uuid"
          :onboarding-client-slugs="onboardingClientSlugs"
          @close="closeAddCustomer" />
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="12">
        <b-table
          :busy="loading"
          :fields="fields"
          :items="customers"
          empty-text="Nothing to show"
          outlined
          primary-key="uuid"
          small
          sticky-header="800px"
          striped>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" />
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(actions)="row">
            <b-button
              variant="light"
              @click="deleteCustomer(row.index, row.item.uuid)">
              <b-icon
                aria-label="Integration enabled"
                font-scale="1"
                icon="trash" />
            </b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Vue from 'vue';
import { get as getClient } from '@/api/onboarding/clients';
import { listCustomers } from '@/api/saml/client/integrations';
import { remove as removeCustomer } from '@/api/saml/client/customers';
import AddCustomer from '@/views/Client/SSO/components/AddCustomer.vue';

export default {
  name: 'EditClientSSOIntegration',
  components: {
    AddCustomer,
  },
  data() {
    return {
      loading: false,
      addingCustomer: false,
      fields: [
        { key: 'customer_key', label: 'Key' },
        { key: 'onboarding_client_slug', label: 'Onboarding Client Slug' },
        { key: 'return_partner_url', label: 'Return Partner URL' },
        { key: 'actions', label: 'Actions' },
      ],
      customers: [],
      onboardingClientSlugs: [],
    };
  },
  beforeMount() {
    this.loading = true;

    this.loadClient()
      .then(() => {
        this.loadList();
      })
      .catch(err => {
        Vue.prototype.$noty.error(`Failed to fetch client: ${err}`);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    openAddCustomer() {
      this.addingCustomer = true;
    },
    closeAddCustomer(refreshList) {
      this.addingCustomer = false;
      if (refreshList) {
        this.loadList();
      }
    },
    deleteCustomer(index, uuid) {
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this?')
        .then(value => {
          if (value) {
            removeCustomer(uuid)
              .then(() => {
                Vue.prototype.$noty.success('Customer removed');
                this.customers.splice(index, 1);
              })
              .catch(err => {
                Vue.prototype.$noty.error(`Failed to remove customer: ${err}`);
              });
          }
        });
    },
    loadList() {
      this.loading = true;
      this.customers = [];
      listCustomers(this.$route.params.uuid)
        .then(customers => {
          this.customers = customers;
        })
        .catch(err => {
          Vue.prototype.$noty.error(`Failed to fetch customers: ${err}`);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadClient() {
      const { client_id: clientId } = this.$route.params;
      return getClient(clientId)
        .then(client => {
          this.onboardingClientSlugs = client.slugs.filter(slug => slug.enabled);
        });
    },
  },
};
</script>
