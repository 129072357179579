import axios from 'axios';

export function create(req) {
  return axios.post('/v1/saml/client/integrations', req)
    .then(({ data }) => data);
}

export function remove(uuid) {
  return axios.delete('/v1/saml/client/integrations', { uuid });
}

export function edit(uuid, data) {
  return axios.patch(`/v1/saml/client/integrations/${uuid}`, data);
}

export function list(params) {
  return axios.get('/v1/saml/client/integrations', { params })
    .then(({ data: { integrations } }) => integrations);
}

export function listCustomers(uuid) {
  return axios.get(`/v1/saml/client/integrations/${uuid}/customers`)
    .then(({ data: { customers } }) => customers);
}

export function createCustomer(uuid, data) {
  return axios.post(`/v1/saml/client/integrations/${uuid}/customers`, data);
}

export default {};
